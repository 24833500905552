import classNames from 'classnames';
import { dayjs } from 'date-utils';
import {
  GoalPartsFragment,
  MyGoalParticipationsDocument,
  useCreateGoalMutation,
  useDeleteGoalMutation,
  useUpdateGoalMutation,
} from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import useMixpanel from 'hooks/useMixpanel';
import { useRouter } from 'next/router';
import { useState } from 'react';
import Modal from 'ui/specific/modal/Modal';
import { routes } from 'utils/routes';
import { GoalFormAdvanced } from './GoalFormAdvanced';
import GoalFormBasic from './GoalFormBasic';
import styles from './GoalModal.module.scss';
import { GoalFormData } from './types';

type Props = {
  initialData?: GoalPartsFragment;
  close: () => void;
};

export const GoalModal = ({ initialData, close }: Props): JSX.Element => {
  const mixpanel = useMixpanel();
  const [showAdvanced, setShowAdvanced] = useState(false);
  const router = useRouter();
  const { profile } = useAuthContext();
  const [createGoal, { loading: creatingGoal }] = useCreateGoalMutation();
  const [deleteGoal, { loading: deletingGoal }] = useDeleteGoalMutation();
  const [updateGoal, { loading: updatingGoal }] = useUpdateGoalMutation();

  function redirectToGoal(slug: string) {
    const asPath = `/${profile?.handle}/goal/${slug}`;
    if (router.asPath !== asPath) {
      router.push(routes.goal, asPath);
    }
  }

  async function handleDelete(goal: GoalPartsFragment) {
    if (creatingGoal || updatingGoal || deletingGoal) return;
    await deleteGoal({
      variables: { goalId: goal.id },
      update(cache) {
        const normalizedGoalId = cache.identify(goal);
        cache.evict({ id: normalizedGoalId });
        cache.gc();
      },
    });
    router.push(routes.goals);
  }

  async function handleSubmit(data: GoalFormData) {
    if (creatingGoal || updatingGoal || deletingGoal) return;
    const startDate = dayjs(data.startDate).toISOString();
    const endDate = dayjs(data.endDate).toISOString();
    const targetValue = Number(data.target);
    if (initialData) {
      const goalId = data.goalId as string;
      const result = await updateGoal({
        variables: { ...data, target: targetValue, startDate, endDate, goalId },
        refetchQueries: [{ query: MyGoalParticipationsDocument, variables: { limit: 20, offset: 0 } }],
        awaitRefetchQueries: true,
      });
      if (result.data?.updateGoal.slug) {
        close();
      }
    } else {
      const result = await createGoal({
        variables: { ...data, target: targetValue, startDate, endDate },
        refetchQueries: [
          {
            query: MyGoalParticipationsDocument,
            variables: { limit: 20, offset: 0, earliestEndDate: dayjs().endOf('day').toISOString() },
          },
        ],
        awaitRefetchQueries: true,
      });
      mixpanel.trackEvent('goal_created', {
        name: result.data?.createGoal.goal.name,
        slug: result.data?.createGoal.goal.slug,
        target: result.data?.createGoal.goal.target,
        autoUpdate: result.data?.createGoal.goal.autoUpdate,
        startDate: result.data?.createGoal.goal.startDate,
        endDate: result.data?.createGoal.goal.endDate,
      });
      if (result.data?.createGoal.goal.slug) {
        redirectToGoal(result.data.createGoal.goal.slug);
      }
    }
  }

  return (
    <Modal close={() => close()}>
      <div className={styles.header}>
        <h1 className={classNames('displayHeader', styles.header)}>
          {initialData ? 'Edit goal' : 'New reading goal'}
        </h1>
      </div>
      {showAdvanced || initialData ? (
        <GoalFormAdvanced onDelete={handleDelete} onSubmit={handleSubmit} initialData={initialData} />
      ) : (
        <GoalFormBasic onSubmit={handleSubmit} setShowAdvanced={() => setShowAdvanced(true)} />
      )}
    </Modal>
  );
};

export default GoalModal;
