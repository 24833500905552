import classNames from 'classnames';
import { forwardRef } from 'react';
import { ChangeHandler } from 'react-hook-form';
import styles from './ToggleSwitch.module.scss';

type Props = {
  id: string;
  checked?: boolean;
  setChecked?: (newVal: boolean) => void;
  onChange?: ChangeHandler;
  name?: string;
  onLabel?: string;
  offLabel?: string;
  // todo - small size
  small?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
};

// use either setChecked (with useState in parent) or onChange (with react-hook-form)

export const ToggleSwitch = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      name,
      checked,
      defaultChecked,
      setChecked,
      onChange,
      small,
      disabled = false,
      onLabel = '',
      offLabel = '',
    }: Props,
    ref
  ): JSX.Element => {
    return (
      <div
        className={classNames(styles.container, {
          [styles.smallSwitch]: small,
          [styles.checked]: checked,
        })}
      >
        <input
          type="checkbox"
          name={name}
          className={styles.checkbox}
          id={id}
          defaultChecked={defaultChecked}
          checked={checked}
          onChange={(e) => {
            if (setChecked) {
              setChecked(e.target.checked);
            }
            if (onChange) {
              onChange(e);
            }
          }}
          disabled={disabled}
          ref={ref}
        />
        {id && (
          <label className={styles.label} htmlFor={id}>
            <span
              className={classNames(styles.inner, { [styles.toggleSwitchDisabled]: disabled })}
              data-yes={onLabel}
              data-no={offLabel}
            />
            <span className={classNames(styles.switch, { [styles.disabled]: disabled })} />
          </label>
        )}
      </div>
    );
  }
);

ToggleSwitch.displayName = 'ToggleSwitch';
