import { dayjs } from 'date-utils';
import { useAuthContext } from 'hooks/useAuth';
import { useForm } from 'react-hook-form';
import { Button, ButtonSimple } from 'ui/generic';
import { ModalTextSlot } from 'ui/specific/modal/Modal';
import styles from './GoalFormBasic.module.scss';
import { GoalFormData } from './types';

type Props = {
  setShowAdvanced: () => void;
  onSubmit: (data: GoalFormData) => void;
};

const GoalFormBasic = ({ setShowAdvanced, onSubmit }: Props): JSX.Element => {
  const currentYear = dayjs().year();
  const { profile } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors, isSubmitting, isSubmitSuccessful },
  } = useForm<GoalFormData>({
    defaultValues: {
      name: `${profile?.name}'s ${currentYear} Reading Goal`,
      autoUpdate: true,
      startDate: dayjs().startOf('year').toISOString(),
      endDate: dayjs().endOf('year').toISOString(),
      target: undefined,
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.inputHolder}>
        <input
          min="1"
          {...register('target', { required: 'Number of books is required' })}
          type="number"
          placeholder="0"
          pattern={'[0-9]*'}
          inputMode={'numeric'}
          autoFocus={true}
        />
        <div className={styles.info}>
          <div>Books in</div>
          <div>{currentYear}</div>
        </div>
      </div>
      <div className={styles.actions}>
        <ButtonSimple onClick={() => setShowAdvanced()} variant="fadedUnderline">
          Advanced setup
        </ButtonSimple>
        <Button disabled={isSubmitting || isSubmitSuccessful} type="submit" variant="focus">
          Create goal
        </Button>
      </div>
      <ModalTextSlot text="New Reading Goal" />
    </form>
  );
};

export default GoalFormBasic;
