import classnames from 'classnames';
import { dayjs } from 'date-utils';
import { useDeviceDetectContext } from 'hooks/useDeviceDetect';
import { useEffect, useReducer } from 'react';
import ReactDatePicker from 'react-date-picker/dist/entry.nostyle';
import { Control, FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { CalendarIcon } from 'ui/icons';
import styles from './DatePicker.module.scss';
import reactDatePickerStyles from './ReactDatePicker.module.scss';

type Props<TFieldValues, TName> = {
  name: TName;
  control: Control<TFieldValues>;
  defaultValue: string;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  rules?: UseControllerProps<TFieldValues>['rules'];
  disabled?: boolean;
};

export function DatePicker<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  control,
  name,
  label,
  defaultValue,
  minDate,
  maxDate,
  rules,
  disabled,
}: Props<TFieldValues, TName>): JSX.Element {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { field } = useController({ name, control, rules });
  const { isDesktop } = useDeviceDetectContext();

  // Hack, that forces the date picker to rerender and so update the input width
  useEffect(() => forceUpdate(), []);

  return (
    <div className={classnames(reactDatePickerStyles.reactDatePicker, styles.datePicker)}>
      {label && <label className={classnames(styles.label, { [styles.disabled]: disabled })}>{label}</label>}
      {isDesktop ? (
        <ReactDatePicker
          disabled={disabled}
          defaultValue={dayjs(defaultValue).isValid() ? dayjs(defaultValue).toDate() : undefined}
          value={field.value ? dayjs(field.value).toDate() : undefined}
          onChange={(val: Date) => {
            if (!val) field.onChange(null);
            if (dayjs(val).isValid()) {
              field.onChange(dayjs(val).toDate());
            }
          }}
          className={classnames(styles.literalDatePicker, {
            hasDate: !field.value,
          })}
          calendarClassName={styles.literalCalendar}
          calendarIcon={<CalendarIcon />}
          clearIcon={null}
          minDate={minDate}
          maxDate={maxDate}
          format="dd/MM/y"
          dayPlaceholder="dd"
          monthPlaceholder="mm"
          yearPlaceholder="yyyy"
          inputRef={field.ref}
        />
      ) : (
        <div>
          <input
            min={minDate ? dayjs(minDate).format('YYYY-MM-DD') : undefined}
            disabled={disabled}
            className={classnames('date-input', styles.nativeInput)}
            type="date"
            placeholder="yyyy-mm-dd"
            value={field.value ? dayjs(field.value).format('YYYY-MM-DD') : undefined}
            onChange={(e) => {
              if (dayjs(e.target.value).isValid()) {
                field.onChange(dayjs(e.target.value).toDate());
              }
            }}
            ref={field.ref}
          />
        </div>
      )}
    </div>
  );
}
