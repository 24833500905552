import { ToggleSwitch } from 'components/basics/ToggleSwitch/ToggleSwitch';
import { DatePicker } from 'components/DatePicker';
import FormError from 'components/forms/FormError';
import { dayjs } from 'date-utils';
import { GoalPartsFragment } from 'generated/graphql';
import { useScreenSize } from 'hooks/useScreenSize';
import { useForm } from 'react-hook-form';
import { Button, ButtonSimple, Input, Label } from 'ui/generic';
import { DeleteIcon } from 'ui/icons';
import { ModalActionButtonSlot } from 'ui/specific/modal/Modal';
import styles from './GoalFormAdvanced.module.scss';
import { GoalFormData } from './types';

type Props = {
  initialData?: GoalPartsFragment;
  onSubmit: (data: GoalFormData) => void;
  submitError?: Record<string, { message: string }>;
  onDelete: (goal: GoalPartsFragment) => void;
};

export const GoalFormAdvanced = ({
  initialData,
  submitError,
  onDelete,
  onSubmit,
}: Props): JSX.Element | null => {
  const { smScreen } = useScreenSize();

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors, isSubmitting, isSubmitSuccessful },
    control,
    watch,
  } = useForm<GoalFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      goalId: initialData?.id || null,
      name: initialData?.name || '',
      autoUpdate: typeof initialData?.autoUpdate === 'boolean' ? initialData.autoUpdate : true,
      startDate: initialData?.startDate || '',
      endDate: initialData?.endDate || '',
      target: initialData?.target,
    },
  });

  const startDate = watch('startDate');
  const autoUpdateValue = watch('autoUpdate');

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Input
          id="name"
          {...register('name', { required: 'Name is required' })}
          placeholder="Goal name"
          label="Name"
        />
        <Label>Time period</Label>
        <div className={styles.dates}>
          <div className={styles.datePickerLeft}>
            <DatePicker
              rules={{ required: 'Start date is required' }}
              defaultValue=""
              label="Start"
              control={control}
              name="startDate"
            />
          </div>
          <div className={styles.datePickerRight}>
            <DatePicker
              rules={{ required: 'End date is required' }}
              defaultValue=""
              label="End"
              control={control}
              name="endDate"
              disabled={!startDate}
              minDate={dayjs(startDate).toDate()}
            />
          </div>
        </div>
        <Input
          id="target"
          {...register('target', { required: 'Number of books is required' })}
          placeholder="Eg. 12"
          label="Number of books to read"
          type="number"
          pattern={'[0-9]*'}
          inputMode={'numeric'}
          min={1}
        />

        <div className={styles.autoUpdate}>
          <span>Automatically add books</span>
          <ToggleSwitch defaultChecked={autoUpdateValue} id="autoUpdate" {...register('autoUpdate')} />
        </div>
        <p className={styles.updateInfo}>All read books will count towards to this goal.</p>
        <p className={styles.updateInfo}>
          If you're making a goal for a specific type of book (eg. biographies, BIPOC authors, etc.) turn this
          off and you'll get asked if it should be part of the goal whenever you finish a book.
        </p>
        {errors && Object.keys(errors).length > 0 && (
          <div className="mt-10">
            <FormError errors={errors} />
          </div>
        )}
        {submitError && Object.keys(submitError).length > 0 && (
          <div className="mt-10">
            <FormError errors={submitError} />
          </div>
        )}
        <div className={styles.button}>
          {!smScreen ? (
            <Button
              disabled={!isDirty || isSubmitting || isSubmitSuccessful}
              type="submit"
              onClick={() => handleSubmit}
              variant="focus"
            >
              {initialData ? 'Save changes' : 'Create goal'}
            </Button>
          ) : (
            <ModalActionButtonSlot>
              <Button
                mini
                disabled={!isDirty || isSubmitting || isSubmitSuccessful}
                type="submit"
                onClick={() => {
                  handleSubmit(onSubmit)();
                }}
                variant="focus"
              >
                {initialData ? 'Save changes' : 'Create goal'}
              </Button>
            </ModalActionButtonSlot>
          )}
        </div>
      </form>

      {initialData && (
        <div className={styles.delete}>
          <ButtonSimple onClick={() => onDelete(initialData)} variant="faded">
            <DeleteIcon /> <span className="ml-1">Delete</span>
          </ButtonSimple>
        </div>
      )}
    </>
  );
};

export default GoalFormAdvanced;
